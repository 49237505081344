module.exports = [{
      plugin: require('/home/james/IdeaProjects/ibcscorp/primeagile-ui/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Merriweather:400,300,300italic,400italic,700,700italic","Merriweather+Sans:400,700"]}},
    },{
      plugin: require('/home/james/IdeaProjects/ibcscorp/primeagile-ui/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dreamhomesbymj","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/github.png"},
    },{
      plugin: require('/home/james/IdeaProjects/ibcscorp/primeagile-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

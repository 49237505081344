// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-markdown-dreamhomesbymj-layoutsets-half-carousel-with-overlay-contact-page-layout-jsx": () => import("/home/james/IdeaProjects/ibcscorp/primeagile-ui/src/markdown/dreamhomesbymj/layoutsets/half-carousel-with-overlay/contact-page-layout.jsx" /* webpackChunkName: "component---src-markdown-dreamhomesbymj-layoutsets-half-carousel-with-overlay-contact-page-layout-jsx" */),
  "component---src-markdown-dreamhomesbymj-layoutsets-half-carousel-with-overlay-not-found-layout-jsx": () => import("/home/james/IdeaProjects/ibcscorp/primeagile-ui/src/markdown/dreamhomesbymj/layoutsets/half-carousel-with-overlay/not-found-layout.jsx" /* webpackChunkName: "component---src-markdown-dreamhomesbymj-layoutsets-half-carousel-with-overlay-not-found-layout-jsx" */),
  "component---src-markdown-dreamhomesbymj-layoutsets-half-carousel-with-overlay-featured-properties-layout-jsx": () => import("/home/james/IdeaProjects/ibcscorp/primeagile-ui/src/markdown/dreamhomesbymj/layoutsets/half-carousel-with-overlay/featured-properties-layout.jsx" /* webpackChunkName: "component---src-markdown-dreamhomesbymj-layoutsets-half-carousel-with-overlay-featured-properties-layout-jsx" */),
  "component---src-markdown-dreamhomesbymj-layoutsets-half-carousel-with-overlay-internal-public-page-layout-jsx": () => import("/home/james/IdeaProjects/ibcscorp/primeagile-ui/src/markdown/dreamhomesbymj/layoutsets/half-carousel-with-overlay/internal-public-page-layout.jsx" /* webpackChunkName: "component---src-markdown-dreamhomesbymj-layoutsets-half-carousel-with-overlay-internal-public-page-layout-jsx" */),
  "component---src-markdown-dreamhomesbymj-layoutsets-half-carousel-with-overlay-landing-page-layout-jsx": () => import("/home/james/IdeaProjects/ibcscorp/primeagile-ui/src/markdown/dreamhomesbymj/layoutsets/half-carousel-with-overlay/landing-page-layout.jsx" /* webpackChunkName: "component---src-markdown-dreamhomesbymj-layoutsets-half-carousel-with-overlay-landing-page-layout-jsx" */)
}

